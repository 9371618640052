
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-white: map-get($colors, 'white');
$color-black-2: map-get($colors, 'black-2');
$color-pink-1: map-get($colors, 'pink-1');
$color-green: map-get($colors, 'green');
$color-gray-1: map-get($colors, 'gray-1');
$color-gray-6: map-get($colors, 'gray-6');
$color-gray-7: map-get($colors, 'gray-7');

// sizes
$size-12: map-get($sizes, 12);
$size-14: map-get($sizes, 14);
$size-15: map-get($sizes, 15);
$size-16: map-get($sizes, 16);
$size-18: map-get($sizes, 18);
$size-20: map-get($sizes, 20);
$size-24: map-get($sizes, 24);
$size-28: map-get($sizes, 28);

[data-component-name="TermsOfUse"] {
  @include size-full;

  position: relative;

  display: flex;
  flex-direction: column;

  img.background {
    @include bg;
  }

  h1 {
    color: $color-white;

    font-size: $size-24;
    line-height: $size-28;
    font-weight: 600;

    margin: 30px;
  }

  form {
    height: 100%;
    padding: 40px 30px 60px;
    background-color: $color-white;
    box-shadow: 0px -10px 20px rgba($color-black-2, 0.2);
    border-radius: 40px 40px 0 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .terms-conditions {
      h2 {
        color: $color-pink-1;

        font-size: $size-12;
        line-height: $size-16;

        margin-bottom: 15px;
      }

      h3 {
        font-weight: 600;
        font-size: $size-18;
        line-height: $size-28;

        margin-bottom: 20px;
      }

      p {
        color: $color-gray-7;

        font-size: $size-14;
        line-height: $size-24;
        margin-bottom: 30px;
      }

      ul {
        margin: 15px 0 30px 35px;
        list-style: disc;

        li {
          color: $color-gray-7;

          font-size: $size-14;
          line-height: $size-24;
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;

      margin-top: auto;

      .btn {
        padding: 15px 45px;
        border-radius: 6px;

        font-weight: 600;
        font-size: $size-15;
        line-height: $size-20;

        &.decline {
          background-color: $color-gray-1;
          color: $color-gray-6;
        }

        &.accept {
          background-color: $color-green;
          color: $color-white;
        }
      }
    }
  }
}
