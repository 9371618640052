
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-white: map-get($colors, 'white');
$color-black-2: map-get($colors, 'black-2');
$color-pink-1: map-get($colors, 'pink-1');
$color-green: map-get($colors, 'green');
$color-gray-1: map-get($colors, 'gray-1');
$color-gray-6: map-get($colors, 'gray-6');
$color-gray-7: map-get($colors, 'gray-7');
$color-gray-8: map-get($colors, 'gray-8');

// sizes
$size-12: map-get($sizes, 12);
$size-14: map-get($sizes, 14);
$size-15: map-get($sizes, 15);
$size-16: map-get($sizes, 16);
$size-18: map-get($sizes, 18);
$size-20: map-get($sizes, 20);
$size-24: map-get($sizes, 24);
$size-28: map-get($sizes, 28);

[data-component-name="Instructions"] {
  @include size-full;

  position: relative;

  display: flex;
  flex-direction: column;

  img.background {
    @include bg;
  }

  h1 {
    color: $color-white;

    font-size: $size-24;
    line-height: $size-28;
    font-weight: 600;

    margin: 30px;
  }

  .instractions-container {
    height: 100%;
    padding: 40px 30px;
    background-color: $color-white;
    box-shadow: 0px -10px 20px rgba($color-black-2, 0.2);
    border-radius: 40px 40px 0 0;
  }

  ol {
    counter-reset: list-counter;
    margin-bottom: 50px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    li {
      $offset: 40px;

      display: flex;
      flex-direction: column;

      margin: 0 0 10px $offset;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        $size: 28px;

        position: absolute;
        top: -2px;
        left: -40px;

        height: $size;
        width: $size;

        counter-increment: list-counter;
        content: counter(list-counter);

        @include flex-center;
        border-radius: 100px;
        background:
          transparent
          linear-gradient(180deg, #A4C600 0%, #2FC8EF 100%)
          0
          0
          no-repeat
          padding-box;

        font-size: $size-14;
        line-height: $size-24;

        color: $color-white;
      }

      p {
        font-size: $size-14;
        line-height: $size-24;

        color: $color-gray-8;
      }

      img.ticket {
        position: relative;
        left: -#{calc($offset / 2)};
      }
    }
  }
}
