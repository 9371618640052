
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-black-1: map-get($colors, 'black-1');

// sizes
$size-13: map-get($sizes, 14);
$size-24: map-get($sizes, 24);

[data-component-name="NavigationLink"] {
  width: 100%;
  height: 100%;

  @include flex-center;

  color: $color-black-1;
  font-size: $size-13;
  line-height: $size-24;
  text-align: center;
  white-space: nowrap;

  padding: 23px 4.26%;
}
