
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-white: map-get($colors, 'white');
$color-gray-1: map-get($colors, 'gray-1');
$color-gray-2: map-get($colors, 'gray-2');
$color-gray-6: map-get($colors, 'gray-6');
$color-black-1: map-get($colors, 'black-1');
$color-black-2: map-get($colors, 'black-2');

// sizes
$size-14: map-get($sizes, 14);
$size-20: map-get($sizes, 20);
$size-24: map-get($sizes, 24);
$size-28: map-get($sizes, 28);

[data-component-name="Scan"] {
  @include size-full;
  padding: 25px 0 calc(80px + 25px) 0;
  min-height: 680px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  color: $color-white;
  line-height: $size-28;

  img.background {
    @include bg;
  }

  img.emoji {
    width: 77px;
    margin-bottom: 16px;
  }

  h1 {
    font-size: $size-24;
    font-weight: 600;
    margin-bottom: 8px;
  }

  p {
    font-size: $size-20;
    margin-bottom: 30px;

    a {
      display: inline;
      text-decoration: underline;
    }
  }

  .scanner {
    $height: 185px;
    padding: 30px;

    width: 100%;
    min-height: $height;

    position: relative;
    margin-bottom: 20px;
    background-color: $color-black-2;

    video,
    canvas {
      height: 100%;
      width: 100%;
      object-fit: cover;

      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    .camera-view {
      min-height: 125px;
      @include absolute-full;
      margin: 30px;
      box-shadow: 0 0 0 30px rgba($color-black-2, 0.6);

      .tl,
      .tr,
      .bl,
      .br {
        $size: 28px;

        width: $size;
        height: $size;

        position: absolute;
      }

      $border-width: 1px;
      $border: $border-width solid $color-white;

      .tl {
        top: -#{$border-width};
        border-top: $border;

        left: -#{$border-width};
        border-left: $border;
      }

      .tr {
        top: -#{$border-width};
        border-top: $border;

        right: -#{$border-width};
        border-right: $border;
      }

      .bl {
        bottom: -#{$border-width};
        border-bottom: $border;

        left: -#{$border-width};
        border-left: $border;
      }

      .br {
        bottom: -#{$border-width};
        border-bottom: $border;

        right: -#{$border-width};
        border-right: $border;
      }
    }
  }

  strong {
    font-size: $size-14;
    line-height: $size-24;
    text-align: center;

    margin-bottom: 75px;
  }

  button {
    @include size(70px);

    border: 2px solid $color-black-1;

    border-radius: 100px;
    background-color: $color-white;
    box-shadow: 0 0 0 5px $color-white;
  }
}
