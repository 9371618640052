
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-white: map-get($colors, 'white');
$color-gray-8: map-get($colors, 'gray-8');

// sizes
$size-16: map-get($sizes, 16);
$size-26: map-get($sizes, 26);

[data-component-name="Maintenance"] {
  @include size-full;
  padding: 30px 30px 50px 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  > img {
    @include bg;

    &.balloons {
      z-index: 1;
      pointer-events: none;
    }
  }

  section {
    height: 65%;
    width: 100%;
    border-radius: 30px;
    padding: 70px 30px;
    overflow-y: hidden;

    border: 5px solid $color-white;
    background:
      transparent
      radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FBFBFB 70%, #e7e7e7 100%)
      center
      no-repeat
      padding-box;

    @include flex-center;
    flex-direction: column;

    text-align: center;
    font-size: $size-16;
    line-height: $size-26;
    color: $color-gray-8;

    img.link {
      margin-bottom: 35px;
    }

    p {
      margin-bottom: 20px;
      white-space: pre-wrap;
    }

    span {
      margin-bottom: 20px;
    }

    img.logo {
      height: 38px;
    }
  }
}
