
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-white: map-get($colors, 'white');
$color-black-2: map-get($colors, 'black-2');

// sizes
$size-14: map-get($sizes, 14);
$size-22: map-get($sizes, 22);
$size-24: map-get($sizes, 24);
$size-20: map-get($sizes, 20);
$size-28: map-get($sizes, 28);
$size-30: map-get($sizes, 30);
$size-36: map-get($sizes, 36);

[data-component-name="Result"] {
  position: relative;
  overflow: hidden;

  @include size-full;
  padding: 50px 30px calc(80px + 50px) 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: $color-white;

  .confetti {
    @include absolute-full;
    pointer-events: none;
  }

  img {
    &.background {
      @include bg;
    }

    &.emoji {
      margin-bottom: 30px;
    }
  }

  strong.reaction {
    font-size: $size-36;
    line-height: $size-28;
    margin-bottom: 20px;
  }

  h1 {
    font-size: $size-20;
    line-height: $size-28;
    font-weight: 400;
    text-align: center;

    margin-bottom: 40px;
  }

  p.won-amount {
    margin-bottom: 25px;

    strong.amount {
      font-size: $size-20;
      line-height: $size-30;
    }
  }

  ul {
    margin-bottom: 20px;

    li {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $color-white;
      margin-right: 10px;

      width: 120px;
      height: 60px;

      color: $color-black-2;
      font-size: $size-20;
      line-height: $size-28;
      font-weight: 600;

      border-radius: 3px;
      box-shadow: 0 0 10px rgba($color-black-2, 0.1);

      &:last-child {
        margin-right: unset;
      }

      &.won {
        box-shadow: inset 0 0 0 3px #A4C600;
      }

      &.lose {
        color: rgba($color-black-2, 0.2);
      }
    }

    &.all-lose {
      display: flex;
      justify-content: space-around;

      li {
        color: $color-black-2;
      }
    }
  }

  p.warning {
    font-size: $size-14;
    line-height: $size-24;
  }

  button.scan {
    border: 1px solid $color-white;
    padding: 15px 35px;
    border-radius: 4px;

    margin-top: auto;

    @include flex-center;

    [data-component-name="IconRefresh"] {
      margin-right: 16px;
    }

    span {
      font-size: $size-14;
      line-height: $size-24;
      color: inherit;
    }
  }
}
