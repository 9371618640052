
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-white: map-get($colors, 'white');
$color-black-2: map-get($colors, 'black-2');
$color-gray-1: map-get($colors, 'gray-1');
$color-gray-2: map-get($colors, 'gray-2');
$color-gray-3: map-get($colors, 'gray-3');

[data-component-name="Navigation"] {
  box-shadow: 0px -5px 20px rgba($color-black-2, 0.2);
  border-radius: 40px 40px 0 0;
  border: 5px solid $color-white;
  height: 80px;

  background:
    transparent
    radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FBFBFB 70%, #e7e7e7 100%)
    center
    no-repeat
    padding-box;
  background-size: calc(100% + 150px) 90px;
  overflow: hidden;

  @include flex-center;

  [data-component-name="NavigationLink"] {
    position: relative;

    &::after {
      content: '';
      width: 1px;
      height: 20px;
      background-color: #ccc;

      position: absolute;
      left: 100%;
    }

    &:last-child::after {
      display: none;
    }
  }

  hr {
    border: unset;
    background-color: $color-gray-3;
    width: 1px;
    height: 20px;
    display: block;
  }
}
