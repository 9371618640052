
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: block;
  width: max-content;
}

button {
  background-color: unset;
  border: none;
  cursor: pointer;
  padding: 0;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

hr {
  border: none;
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

span {
  display: block;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

img {
  display: block;
}

label {
  display: block;
}

input {
  border: none;
  outline: none;
}

textarea {
  outline: none;
}
.lose.hn{
  display: none;
}
[data-component-name=TermsOfUse] form .terms-conditions p:last-child {
  margin-bottom: 80px;
}
[data-component-name=TermsOfUse] form .buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #fff;
  padding: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 38%);
}
[data-component-name=WinningNumbers] [data-component-name=ScanAnotherTicketButton]{
  border: 1px solid #fff;
  margin: 0 auto 30px;
  width: calc(100% - 60px);
}
[data-component-name=WinningNumbers] [data-component-name=ScanAnotherTicketButton] span{
  color:#fff;
}
[data-component-name=WinningNumbers] [data-component-name=ScanAnotherTicketButton] [data-component-name=IconRefresh] {
  fill: #fff;
  height: 21px;
}
[data-component-name=WinningNumbers] section ul.sections{
  display: flex;
  flex-direction: column-reverse;
}
[data-component-name=Result] p.won-amount {
  margin-bottom: 20px;
}
[data-component-name=Result] ul {
  margin-bottom: 15px;
}
/* [data-component-name=Scan] .scanner {
  min-height: 16vh;
} */
[data-component-name=Scan] strong {
  margin-bottom: 3vh;
}

