
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-black-2: map-get($colors, 'black-2');

[data-component-name="Overlay"] {
  @include absolute-full;
  @include flex-center;

  background-color: rgba($color-black-2, 0.6);
}
