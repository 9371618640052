
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-white: map-get($colors, 'white');
$color-black-2: map-get($colors, 'black-2');
$color-pink-1: map-get($colors, 'pink-1');
$color-green: map-get($colors, 'green');
$color-gray-1: map-get($colors, 'gray-1');
$color-gray-6: map-get($colors, 'gray-6');
$color-gray-7: map-get($colors, 'gray-7');
$color-green: map-get($colors, 'green');
$color-pink-2: map-get($colors, 'pink-2');

// sizes
$size-12: map-get($sizes, 12);
$size-14: map-get($sizes, 14);
$size-15: map-get($sizes, 15);
$size-16: map-get($sizes, 16);
$size-18: map-get($sizes, 18);
$size-20: map-get($sizes, 20);
$size-24: map-get($sizes, 24);
$size-26: map-get($sizes, 26);
$size-28: map-get($sizes, 28);

[data-component-name="WinningNumbers"] {
  position: relative;

  display: flex;
  flex-direction: column;

  overflow-y: auto;

  img {
    &.background {
      @include bg;
    }

    &.balloon {
      position: absolute;
      z-index: 1;
      width: max-content;
      top: 50px;
      left: 13.3%;
    }
  }

  h1 {
    color: $color-white;

    font-size: $size-24;
    line-height: $size-28;
    font-weight: 600;

    margin: 40px auto 100px auto;
  }

  section {
    height: 100%;
    padding: 40px 30px;
    background-color: $color-white;
    box-shadow: 0px -10px 20px rgba($color-black-2, 0.2);
    border-radius: 40px 40px 0 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ul.sections {
      margin-bottom: 50px;

      li.section {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        [data-component-name="Prize"] {}

        span.count {
          padding: 14px 20px;
          background-color: $color-green;
          color: $color-white;
          border-radius: 10px;
          margin-bottom: 15px;
        }

        ul.list {
          li.item {
            font-size: $size-16;
            line-height: $size-26;
            font-weight: 600;

            margin-bottom: 5px;

            &:first-child {
              font-size: $size-14;
              line-height: $size-26;
              font-weight: normal;
              letter-spacing: 0.28px;
              color: $color-pink-2;
            }
          }
        }
      }
    }

    button.scan {
      border: 1px solid $color-green;
      padding: 15px 35px;
      border-radius: 4px;

      margin-top: auto;

      @include flex-center;

      [data-component-name="IconRefresh"] {
        margin-right: 16px;
        fill: $color-green;
      }

      span {
        font-size: $size-14;
        line-height: $size-24;
        color: $color-green;
      }
    }
  }
}
