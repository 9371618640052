
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-white: map-get($colors, 'white');
$color-black-1: map-get($colors, 'black-1');
$color-pink-2: map-get($colors, 'pink-2');

// sizes
$size-13: map-get($sizes, 13);
$size-14: map-get($sizes, 14);
$size-18: map-get($sizes, 18);
$size-22: map-get($sizes, 22);
$size-24: map-get($sizes, 24);
$size-30: map-get($sizes, 30);

[data-component-name="Prize"] {
  position: relative;

  img.outline {
    width: 100%;
  }

  .left,
  .right {
    position: absolute;
    z-index: 0;

    top: 0;
    bottom: 0;

    img.gradient {
      @include absolute-full;
      @include size-full;
    }
  }

  .left {
    left: 0;
    right: calc(100% - 44.5%);

    ul {
      position: relative;
      z-index: 1;

      @include size-full;
      @include flex-center;
      flex-direction: column;

      color: $color-white;

      li {
        &.icon {}

        &.prize-number {
          font-size: $size-13;
          line-height: $size-22;
        }

        &.amount {
          font-size: $size-18;
          line-height: $size-22;
        }
      }
    }
  }

  .right {
    right: 0;
    left: calc(100% - 56%);
    @include flex-center;

    ul {
      li {
        &.label {
          font-size: $size-14;
          line-height: $size-30;
          letter-spacing: 0.28px;
          color: $color-pink-2;
        }

        &.number {
          font-size: $size-24;
          line-height: $size-30;
          font-weight: 600;
          letter-spacing: 0.48px;
          color: $color-black-1;
        }
      }
    }
  }
}
