
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-gray-4: map-get($colors, 'gray-4');

// size
$size-14: map-get($sizes, 14);

[data-component-view="SplashScreen"] {
  position: relative;
  height: 100%;
  width: 100%;

  .author {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    p.description {
      font-size: $size-14;
      text-align: center;
      color: $color-gray-4;
    }

    .logo {
      width: 270px;
    }
  }

  img {
    position: absolute;
    left: 50%;
    bottom: 60px;
    transform: translate(-50%, 0);
  }
}
