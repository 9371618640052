
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
#root { height: 100% }

[data-component-name="App"] {
  width: 100%;
  height: 100%;
  overflow: auto;

  position: relative;

  [data-component-name="Navigation"] {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  [data-component-name="Overlay"] {
    opacity: 0;
    pointer-events: none;
    transition: 0.25s ease;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }
  }
}
