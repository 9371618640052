
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-white: map-get($colors, 'white');
$color-gray-8: map-get($colors, 'gray-8');

// sizes
$size-14: map-get($sizes, 14);
$size-18: map-get($sizes, 18);
$size-28: map-get($sizes, 28);
$size-26: map-get($sizes, 26);

[data-component-name="NoCamera"] {
  @include size-full;
  padding: 30px 30px 50px 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > img {
    @include bg;
    margin-bottom: 50px;
  }

  section {
    text-align: center;
    @include flex-center;
    flex-direction: column;

    h1 {
      font-size: $size-26;
      line-height: $size-28;
      color: $color-white;
      margin-bottom: 30px;
      font-weight: 600;
    }

    h2 {
      font-size: $size-18;
      color: $color-white;
      margin-bottom: 120px;
      font-weight: 300;
    }

    span {
      font-size: $size-14;
      color: $color-white;
      margin-bottom: 20px;
    }

    img.emoji {
      margin-bottom: 55px;
      height: 150px;
    }

    img.logo {
      height: 50px;
    }
  }
}
