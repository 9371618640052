
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
@import "normalize.css/normalize.css";
@import "../css/reset.css";
@import "font";

* {
  font-family: 'Inter', sans-serif;
}

:root {
  --doc-height: 100%;
 }

 html,
 body {
  padding: 0;
  margin: 0;
  height: 100vh;
  height: var(--doc-height);
 }

