@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin absolute-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin size-full {
  width: 100%;
  height: 100%;
}

@mixin bg {
  @include absolute-full;
  @include size-full;

  z-index: -1;

  object-fit: cover;
}

@mixin size($size) {
  width: $size;
  height: $size;

  min-width: $size;
  min-height: $size;

  max-width: $size;
  max-height: $size;
}
