
          @import "src/assets/scss/_variables.scss";
          @import "src/assets/scss/_mixins.scss";
        
// colors
$color-green: map-get($colors, 'green');

// sizes
$size-14: map-get($sizes, 14);
$size-24: map-get($sizes, 24);

[data-component-name="ScanAnotherTicketButton"] {
  border: 1px solid $color-green;
  padding: 15px 35px;
  border-radius: 4px;
  width: 100%;

  margin-top: auto;

  @include flex-center;

  [data-component-name="IconRefresh"] {
    margin-right: 16px;
    fill: $color-green;
  }

  span {
    font-size: $size-14;
    line-height: $size-24;
    color: $color-green;
  }
}
